@import '../configs/color.css';

.nav-item-dropdown{
    background: transparent;
    color: white;
}

.dropdown-menu {
    z-index: 1000;
    min-width: 10rem;
    padding: 0;
    margin: 0;
    font-size: 13px;
    color: #5a6169;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.375rem !important;
    -webkit-box-shadow: 0 0.5rem 4rem rgba(0, 0, 0, 0.11), 0 10px 20px rgba(0, 0, 0, 0.05), 0 2px 3px rgba(0, 0, 0, 0.06);
    box-shadow: 0 0.5rem 4rem rgba(0, 0, 0, 0.11), 0 10px 20px rgba(0, 0, 0, 0.05), 0 2px 3px rgba(0, 0, 0, 0.06);
}

.dropdown-item{
    font-family: 'Raleway', sans-serif !important;
    color: $gris_oscuro !important;
    font-size: 13px !important;
}

.dropdown .dropdown-item:hover{
    background-color: $naranja !important;
    border-radius: 0.375rem !important;
    color: white !important;
    outline: none !important;
}

.dropdown-item.active, .dropdown-item:active {
    color: white;
    background-color: $naranja !important;
    outline: none !important;
}

.dropdown .btn-light:hover {
    color: white !important;
    background-color: transparent !important;
    box-shadow: none;
}

.main-navbar .navbar .navbar-nav .dropdown-menu {
    border-radius: 0.375rem !important;
}

.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show > .btn-light.dropdown-toggle {
    color: white !important;
    background-color: transparent !important;
    box-shadow: none !important;
}

.main-navbar .navbar {
    height: 3rem;
}

.nav-item-dropdown {
    padding: 0.25rem 0 0 0;
}

.main-navbar .navbar .nav-link {
    min-width: 3rem;
    padding: 0 0 0.25rem 0;
}
