@keyframes color {
    0%{
        color: #BE7D3C;
    }
    50%{
        color: #53A513;
    }
    100%{
        color: #BE7D3C;
    }
}
