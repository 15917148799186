@import '../configs/color.css';

/* Bootstrap table */
.react-bs-container-body {
    overflow-x: hidden;
    /* overflow-x: scroll !important; */
    /* @media (min-width: $sm) { */
    /* } */
}
.table-wrap{
    td {
        white-space: normal !important;
    }
}
.react-bs-table {
    border: none !important;
    thead {
        padding: 0.2rem !important;
        color: white;
        background-color: $azul !important;
    }
    th {
        padding-top:    0.5rem !important;
        padding-bottom: 0.5rem !important;
        text-transform: uppercase;
        font-weight: bold !important;
        font-size: 13px !important;
    }
    th:first-child {
        border-top-left-radius: 8px !important;
    }
    th:last-child {
        border-top-right-radius: 8px !important;
    }
    th, td, tr {
        border: none !important;
    }
    td {
        font-weight: 500 !important;
        font-size: 13px !important;
        color: $gris_oscuro !important;
    }
    tbody {
        tr:nth-child(2n + 1) {
            background-color: white !important;
        }
        tr:nth-child(2n) {
            background-color: $gris_claro;
        }
        tr:last-child {
            td:first-child {
                border-bottom-left-radius: 8px !important;
            }
            td:last-child {
                border-bottom-right-radius: 8px !important;
            }
        }
    }
}
/* Paginacion de la tabla */
.page-item.disabled .page-link {
    color: $gris;
}

.page-item.active .page-link {
    color: #fff;
    background-color: $azul !important;
    border-color: $azul !important;
}

.pagination {
    padding-left: 0;
    list-style: none;
    border-radius: 15px !important;
    font-family: 'Raleway', sans-serif !important;
    font-size: 14px !important;
}

/* .react-bs-table-pagination {
    .row {
        div:first-child {
            display: none !important;
        }
        div:last-child {
            display: flex !important;
        }
    }
} */

@media only screen and (max-width: 768px) {
    .react-bs-container-body {
        overflow-x: scroll !important;
    }
    .react-bs-table-container .table {
        min-width: 900px !important;
        /* max-width: 2000px !important; */
    }
}

.react-bs-container-header{
    color: #fff !important;
    background: $azul;
    padding: 1px !important;
    border-radius: 1rem 1rem 0 0;
}


.react-bs-container-header th{
    color: white;
    text-align: center;
}
