@import './breakpoints.css';
@import './color.css';

h1,
h2,
h3,
h4,
h5,
h6 {
    color: white;
}

h1, .h1 {
    font-size: 20pt;
    font-weight: bold;
    margin-bottom: 0 !important;
    font-family: 'Raleway', sans-serif !important;
    @media (max-width: $md) {
        font-size: 16pt !important;
        line-height: 1.5rem !important;
    }
}
h2, .h2 {
    font-size: 17pt;
    font-weight: bold;
    margin-bottom: 0 !important;
    font-family: 'Raleway', sans-serif !important;
}
h3, .h3 {
    font-size: 15pt;
    font-weight: bold;
    margin-bottom: 0 !important;
    font-family: 'Raleway', sans-serif !important;
}
h4, .h4 {
    font-size: 13pt;
    margin-bottom: 0 !important;
    font-family: 'Raleway', sans-serif !important;
}
h5, .h5 {
    font-size: 12pt;
    margin-bottom: 0 !important;
    font-family: 'Raleway', sans-serif !important;
}
label, .label {
    font-size: 13px;
    font-weight: bold !important;
    padding-left: 5px !important;
    color: $gris_oscuro !important;
}
span {
    font-size: 13px;
}

.tachado {
    text-decoration: line-through;
}
.tachado-touch {
    text-decoration: line-through;
    cursor: pointer;
}

.bold{
    font-weight: bold;
}

.light{
    font-weight: lighter;
}

.regular{
    font-weight: 400;
}

.medium{
    font-weight: 500;
}

.text-sm{
    font-size: 1rem;
}

.text-md{
    font-size: 1.5rem;
}

.text-lgs{
    font-size: 1.8rem;
}

.text-lg{
    font-size: 20pt;
    @media (max-width: $md) {
        font-size: 16pt !important;
    }
}

.text-xs {
    font-size: 0.8rem;
}

.text-sm {
    font-size: 1rem;
}

.text-qts {
    font-size: 1.3rem;
}

.text-equal {
    font-size: 13px;
}

.text-xsm {
    font-size: 11px;
}

.total-pagar {
    font-size: 18px;
}

a{
    text-decoration: none !important;
}

.ocultar-texto-movil {
    @media (max-width: $md) {
        visibility: hidden !important;
    }
}

.puntero{
    text-decoration: pointer !important;
}

.parrafo-dos-lineas{
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-40{
    font-size: 40px;
    font-weight: 600;
}

.text-35{
    font-size: 35px;
    font-weight: 600;
}
