@import "../configs/color.css";

.rc-tabs-tab {
    background-color: transparent;
    color: white;
    border-bottom: 4px solid white;
}

.rc-tabs-tab-active,
.rc-tabs-tab-active {
    font-weight: bold;
    color: $naranja !important;
    /* background-color: white; */
    border-bottom: 4px solid $naranja !important;
}

.rc-tabs-ink-bar {
    background-color: $naranja;
}

.rc-tabs-tabpane-active {
    min-height: 55vh !important;
}

/* .rc-tabs-top .rc-tabs-tab {
    margin: 0px;
    padding: 10px 50px 10px 50px;
    color: #000000;
} */

.rc-tabs-tab:hover {
    font-weight: bold;
    color: $naranja !important;
}

.rc-tabs-top,
.rc-tabs-bar {
    border-bottom: none !important;
}

.rc-tabs-nav-wrap {
    background-color: transparent;
}

.rc-tabs-bottom .rc-tabs-tab {
    padding-left: 30px;
    padding-right: 30px;
}


.tabsNegro .rc-tabs-tab {
    background-color: transparent;
    color: black;
    border-bottom: 4px solid black;
}
