.terminos-estilo {
    overflow-y: auto;
    max-height: 70vh;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.terminos-estilo .c2 {
    background-color: #ffffff;
    margin-left: 35.4pt;
    padding-top: 0pt;
    padding-bottom: 14pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.terminos-estilo .c6 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;

    font-style: normal
}

.terminos-estilo .c3 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 14pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.terminos-estilo .c5 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt
}

.terminos-estilo .c1 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11.5pt;

    font-style: normal
}

.terminos-estilo .c9 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;

    font-style: normal
}

.terminos-estilo .c0 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11.5pt;

    font-style: normal
}

.terminos-estilo .c8 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: center
}

.terminos-estilo .c4 {
    text-indent: 35.4pt
}

.terminos-estilo .title {
    padding-top: 0pt;
    color: #000000;
    font-size: 28pt;
    padding-bottom: 0pt;

    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.terminos-estilo .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;

    line-height: 1.0791666666666666;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}

.terminos-estilo li {
    color: #000000;
    font-size: 11pt;
}

.terminos-estilo p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
}
