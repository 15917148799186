@import '../configs/color.css';

.main-sidebar{
    top: 0;
    position: fixed;
    height: 100vh;
    box-shadow: none !important;
    /* background: $gris_oscuro !important; */
    z-index: 107000;
    will-change: transform;
    transition: -webkit-transform 0.2s ease-in-out;
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.main-sidebar .navbar-brand {
    overflow: hidden;
    height: 7rem;
    /* background-color: $gris_oscuro; */
}

.main-sidebar .toggle-sidebar {
    border-left: none !important;
}

.main-logo {
    height: 75px !important;
}

/* ANTD MENU */
.ant-menu {
    width: 100% !important;
    margin-top: 1rem;
    border-right: none !important;
    background-color: $gris;
    color: white;
    min-height: 68% !important;
}

.icon-menu {
    font-size: 15px !important;
    /* margin-right: 0.5rem !important; */
    color: white;
    text-align: center;
    width: 2.285714em !important;
}

.ant-menu-submenu-arrow {
    display: none;
}

.ant-menu-submenu-title > span {
    font-size: 13px;
    font-weight: bold;
    color: white !important;
}

.ant-menu-item > span {
    font-size: 13px;
    font-weight: bold;
    color: white;
}

.ant-menu-sub {
    /* padding-left: 2.5rem !important; */
    background-color: $gris_oscuro !important;
    color: white;
    font-size: 13px;
    font-weight: bold;
}

.ant-menu-item-only-child {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.sub-menu-barra {
    border-radius: 2px;
    height: 2rem;
    width: 0.4rem;
    position: absolute;
    left: 10%;
    z-index: 8;
    background-color: $gris_oscuro;
}

.ant-menu-item-selected {
    background-color: $gris_oscuro !important;
    color: white;
}

.ant-menu-item-only-child:hover {
    color: $naranja;
}

.ant-menu-item-selected::after {
    display: none !important;
}

.ant-menu-item:hover, .ant-menu-item-selected, .ant-menu-submenu:hover {
    background-color: $gris_oscuro !important;
    span {
        color: $naranja !important;
    }

    .icon-menu {
        color: $naranja;
    }
}
