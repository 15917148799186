@import '../configs/color.css';

/*Notificaciones*/
.titulo {
    margin-bottom: 0.4rem;
}

.notification::before {
    color: white !important;
}

.notification-info:before {
    content: none !important;
}

.notification-info {
    background-color: $azul !important;
    border: 2px solid $azul !important;
    padding: 5px !important;
    border-radius: 5px !important;
    box-shadow: 0px 3px 6px #00000029 !important;
}

.noti-min-heigth {
    min-height: 70px;
}

.notification-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white !important;

    .title {
        font-weight: bold;
        font-size: 13px;
    }
}

.notification-dismiss {
    background-color: $naranja !important;
    width:  18px !important;
    height: 18px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px !important;
}

.notification-icono {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 100%;

}

.notifications-tr{
    z-index: 107000 !important;
}

.push-icono {
    width: 40px;
    background-color: white;
    border-radius: 50%;
    padding: 5px;
}
