.google-maps-marker {
    position: relative;
    z-index: 1;
    width: 250px;
    height: 150px;
    cursor: pointer;
}
.google-maps-marker .marker_content {
    visibility: hidden;
    position: absolute;
    z-index: 2;
    width: 200px;
    transform: translate(0%, -110%);
}
/** Chincheta */
.google-maps-marker .marker_indicator {
    position: absolute;
    z-index: 3;
    transform: translate(-50%, -100%);
    width: 20px;
    height: 25px;
}
.marker_indicator:hover {
    width: 26px;
    height: 33px;
}
.marker_indicator:hover + .marker_content {
    /* Aplica el estilo al elemento hermano */
    visibility: visible;
}
/** Chincheta grande */
.google-maps-marker .marker_indicator_grande {
    position: absolute;
    z-index: 3;
    transform: translate(-50%, -100%);
    width: 30px;
    height: 40px;
}
.marker_indicator_grande:hover {
    width: 41px;
    height: 48px;
}
.marker_indicator_grande:hover + .marker_content {
    /* Aplica el estilo al elemento hermano */
    visibility: visible;
}
