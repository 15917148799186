@import "../configs/color.css";

.card {
    /* overflow: hidden; */
    background-color: $gris_claro;
    border: none;
    border-radius: 10px;
    -webkit-box-shadow: none;
    box-shadow: none;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: initial;
}

.card-login{
    /* overflow: hidden; */
    background-color: white;
    border: none;
    border-radius: 10px;
    -webkit-box-shadow: none;
    box-shadow: none;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: initial;
}

.card-empty {
    border: none;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: initial;
    background-color: $gris;
}

.card-proyecto {
    border: none;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: initial;
    background-color: white;
}

.borde-naranja{
    border-left: 20px solid $naranja;
}

.borde-azul{
    border-left: 20px solid $azul;
}

.borde-verde{
    border-left: 20px solid $verde;
}

.borde-gris-oscuro{
    border-left: 20px solid $gris_oscuro;
}

.borde-rojo{
    border-left: 20px solid $rojo;
}

.card-tabla {
    border: none;
    border-radius: 10px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: initial;
    background-color: $gris_claro;
}


.card-tabla .card-content-form{
    background-color: $gris_claro;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    padding: 3.5rem;
    @media (max-width: $md) {
        padding: 1rem;
    }

}
.card-tabla .card-content{
    background-color: $gris_claro;
    border-radius: 10px;
}

.card-empty .card-content{
    background-color: $gris;
    border-radius: 10px;
}
.card > hr {
    margin-right: 0;
    margin-left: 0
}

.card-small {
    box-shadow: none !important;
}

.card-small .card-body, .card-small .card-footer, .card-small .card-header {
    padding: 1rem;
}

.card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.card-body {
    flex: 1 1 auto;
    padding: 1.25rem
}

.card-title {
    margin-bottom: .75rem
}

.card-subtitle {
    margin-top: -.375rem
}

.card-subtitle, .card-text:last-child {
    margin-bottom: 0
}

.card-link:hover {
    text-decoration: none
}

.card-link + .card-link {
    margin-left: 1.25rem
}

.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, .125)
}

.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0
}

.card-header + .list-group .list-group-item:first-child {
    border-top: 0
}

.card-footer {
    padding: .75rem 1.25rem;
    background-color: rgba(0, 0, 0, .03);
    border-top: 1px solid rgba(0, 0, 0, .125)
}

.card-footer:last-child {
    border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px)
}

.card-header-tabs {
    margin-bottom: -.75rem;
    border-bottom: 0
}

.card-header-pills, .card-header-tabs {
    margin-right: -.625rem;
    margin-left: -.625rem
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem
}

.card-img {
    width: 100%;
    border-radius: calc(.25rem - 1px)
}

.card-img-top {
    width: 100%;
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
}

.card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(.25rem - 1px);
    border-bottom-left-radius: calc(.25rem - 1px)
}

.card-deck {
    display: flex;
    flex-direction: column
}

.card-deck .card {
    margin-bottom: 15px
}

@media (min-width: 576px) {
    .card-deck {
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px
    }

    .card-deck .card {
        display: flex;
        flex: 1 0;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px
    }
}

.card-group {
    display: flex;
    flex-direction: column
}

.card-group > .card {
    margin-bottom: 15px
}

@media (min-width: 576px) {
    .card-group {
        flex-flow: row wrap
    }

    .card-group > .card {
        flex: 1 0;
        margin-bottom: 0
    }

    .card-group > .card + .card {
        margin-left: 0;
        border-left: 0
    }

    .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .card-group > .card:not(:last-child) .card-header, .card-group > .card:not(:last-child) .card-img-top {
        border-top-right-radius: 0
    }

    .card-group > .card:not(:last-child) .card-footer, .card-group > .card:not(:last-child) .card-img-bottom {
        border-bottom-right-radius: 0
    }

    .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group > .card:not(:first-child) .card-header, .card-group > .card:not(:first-child) .card-img-top {
        border-top-left-radius: 0
    }

    .card-group > .card:not(:first-child) .card-footer, .card-group > .card:not(:first-child) .card-img-bottom {
        border-bottom-left-radius: 0
    }
}

.card-columns .card {
    margin-bottom: .75rem
}

.card-gris-encabezado{
    border-radius: 1.25rem;
    padding: 1rem;
}

@media (min-width: 576px) {
    .card-columns {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 1.25rem;
        -moz-column-gap: 1.25rem;
        grid-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1
    }

    .card-columns .card {
        display: inline-block;
        width: 100%
    }
}

@media (max-width: 768px) {
    .card-evento-titulo {
        font-size: 10pt;
    }
    .card-evento-subtitulo {
        font-size: 9pt;
    }

    .card-evento-texto {
        font-size: 9pt;
    }
}


