@import "../../../../../style/configs/breakpoints.css";

.login-overlay .panel-body {
    min-height: 244px;
}
.has-feedback {
    position: relative;
}
.form-control-feedback {
    right: 10px;
    left: auto;
    top: 10px;
    position: absolute;
}
.login-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
.card-login {
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    padding: 2rem;
}
.bienvenida {
    color: white;
    font-weight: bold;
}
.buttons-box {
    display: flex;
    align-items: center;
    justify-content: center;
}
.contenedor-principal{
    height: 100vh;
}
.registro{
    height: 100%;
}
.contenedor-principal-form{
    height: 100vh;
    @media (max-width: $sm) {
        height: 100%;
    }
}

.blue-gradient-bg {
    min-height: 100vh;
    /* background-image: url("../../../../../assets/img/back_login.png") ; */
    /* background-color: #55c4d4; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: #323232;
    font-family: 'Raleway', sans-serif;
    font-size: 13px;
}

.bold{
    font-weight: bold;
}

.semi-bold{
    font-weight: 500;
}

.login .btn-primary{
    background-color: #FA7D09 !important;
    border-color: #FA7D09 !important;
    color: white !important;
    font-weight: bold !important;
}
.login .btn-secundary{
    background-color: #10426D !important;
    border-color: #10426D !important;
    color: white !important;
    font-weight: bold !important;
}

.naranja{
    color: #FA7D09 !important;
}
