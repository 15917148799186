@import "../configs/color.css";

.btn{
    border: 1px solid transparent;
    border-radius: 10px !important;
    /* padding: 0.4rem 2rem !important; */
    font-size: .75rem !important;
    line-height: 1.125 !important;
    font-weight: bold !important;
    font-family: 'Raleway', sans-serif !important;
    justify-content: center !important;
    z-index: 1000 !important;
}

/* Botones principales */
.btn-primary {
    color: $naranja;
    background-color: white;
    border-color: $naranja;
    box-shadow: 1px 1px 2px #F47D2099;
}

.btn-primary:hover {
    color: #fff;
    background-color: $naranja;
    border-color: $naranja;
    box-shadow: 1px 1px 2px #F47D2099;
}

.btn-primary:focus {
    color: $naranja;
    background-color: white;
    border-color: $naranja;
    box-shadow: 1px 1px 2px #F47D2099;
}

.btn-secondary {
    color: $azul;
    background-color: white;
    border-color: $azul;
    box-shadow: 1px 1px 2px #10426D99;
}

.btn-secondary:hover {
    color: #fff;
    background-color: $azul;
    border-color: $azul;
    box-shadow: 1px 1px 2px #10426D99;
}

.btn-secondary:focus {
    color: $azul;
    background-color: white;
    border-color: $azul;
    box-shadow: 1px 1px 2px #10426D99;
}


.btn-success {
    color: $verde;
    background-color: white;
    border-color: $verde;
    box-shadow: 1px 1px 2px #10426D99;
}

.btn-success:hover {
    color: #fff;
    background-color: $verde;
    border-color: $verde;
    box-shadow: 1px 1px 2px #10426D99;
}

.btn-success:focus {
    color: $verde;
    background-color: white;
    border-color: $verde;
    box-shadow: 1px 1px 2px #10426D99;
}

.btn-dark {
    color: $gris_oscuro;
    background-color: white;
    border-color: $gris_oscuro;
    box-shadow: 1px 1px 2px #D2D2D299;
}

.btn-dark:hover {
    color: #fff;
    background-color: $gris_oscuro;
    border-color: $gris_oscuro;
    box-shadow: 1px 1px 2px #D2D2D299;
}

.btn-dark:focus {
    color: $gris_oscuro;
    background-color: $gris_claro;
    border-color: $gris_oscuro;
    box-shadow: 1px 1px 2px #D2D2D299;
}

.btn-danger {
    color: $rojo;
    background-color: white;
    border-color: $rojo;
    box-shadow: 1px 1px 2px #D9202799;
}

.btn-danger:hover {
    color: #fff;
    background-color: $rojo;
    border-color: $rojo;
    box-shadow: 1px 1px 2px #D9202799;
}

.btn-danger:focus {
    color: $rojo;
    background-color: white;
    border-color: $rojo;
    box-shadow: 1px 1px 2px #D9202799;
}

/* Botones de cards de proyectos */
.btn-rojo {
    color: #fff;
    background-color: $rojo;
    border-color: $rojo;
    box-shadow: 1px 1px 2px #7EA04D99;
}

.btn-rojo:hover {
    color: #fff;
    background-color: $rojo;
    border-color: $rojo;
    box-shadow: 1px 1px 2px #7EA04D99;
}

.btn-rojo:focus {
    color: #fff;
    background-color: $rojo;
    border-color: $rojo;
    box-shadow: 1px 1px 2px #7EA04D99;
}

.btn-naranja {
    color: #fff;
    background-color: $naranja;
    border-color: $naranja;
    box-shadow: 1px 1px 2px #7EA04D99;
}

.btn-naranja:hover {
    color: #fff;
    background-color: $naranja;
    border-color: $naranja;
    box-shadow: 1px 1px 2px #7EA04D99;
}

.btn-naranja:focus {
    color: #fff;
    background-color: $naranja;
    border-color: $naranja;
    box-shadow: 1px 1px 2px #7EA04D99;
}

.btn-gris-oscuro {
    color: #fff;
    background-color: $gris_oscuro;
    border-color: $gris_oscuro;
    box-shadow: 1px 1px 2px #7EA04D99;
}

.btn-gris-oscuro:hover {
    color: #fff;
    background-color: $gris_oscuro;
    border-color: $gris_oscuro;
    box-shadow: 1px 1px 2px #7EA04D99;
}

.btn-gris-oscuro:focus {
    color: #fff;
    background-color: $gris_oscuro;
    border-color: $gris_oscuro;
    box-shadow: 1px 1px 2px #7EA04D99;
}

.btn-azul {
    color: #fff;
    background-color: $azul;
    border-color: $azul;
    box-shadow: 1px 1px 2px #7EA04D99;
}

.btn-azul:hover {
    color: #fff;
    background-color: $azul;
    border-color: $azul;
    box-shadow: 1px 1px 2px #7EA04D99;
}

.btn-azul:focus {
    color: #fff;
    background-color: $azul;
    border-color: $azul;
    box-shadow: 1px 1px 2px #7EA04D99;
}

.btn-verde {
    color: #fff;
    background-color: $verde;
    border-color: $verde;
    box-shadow: 1px 1px 2px #7EA04D99;
}

.btn-verde:hover {
    color: #fff;
    background-color: $verde;
    border-color: $verde;
    box-shadow: 1px 1px 2px #7EA04D99;
}

.btn-verde:focus {
    color: #fff;
    background-color: $verde;
    border-color: $verde;
    box-shadow: 1px 1px 2px #7EA04D99;
}

/* Otros */
.btn-crear {
    background-color: white !important;
    border-color: $naranja !important;
    font-weight: bold !important;
}
