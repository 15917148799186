@import '../configs/color.css';

/* .form-control:hover {
    border-color: $naranja !important;
}

.form-control:focus, .form-control:focus:hover {
    border-color: $naranja !important;
} */

.form-control {
    display: block;
    width: 100%;
    height: 2rem;
    padding: .375rem .75rem;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;
    color: $gris_oscuro !important;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid $gris_oscuro;
    border-radius: 5px;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}
.form-control-inline {
    height: 2rem;
    padding: .375rem .75rem;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;
    color: $gris_oscuro !important;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid $gris_oscuro;
    border-radius: 5px;
}

@media (prefers-reduced-motion: reduce) {
    .form-control {
        -webkit-transition: none;
        transition: none
    }
}

.form-control::-ms-expand {
    background-color: initial;
    border: 0
}

.form-control:focus {
    border-color: $naranja !important;
    outline: 0;
    box-shadow: unset !important;
}

.form-control:hover {
    border-color: $naranja !important;
}

.form-control:focus, .form-control:focus:hover {
    border-color: $naranja !important;
    outline: 0;
    box-shadow: unset !important;
}

.form-control:disabled, .form-control[readonly] {
    background-color: #e2e2e2 !important;
    opacity: 2 !important;
}

.form-control:disabled:hover, .form-control[readonly]:hover{
    border: 1px solid $gris_oscuro !important;
}

/*CAMPO INVALIDO*/
.invalid-feedback{
    color: $rojo;
}
.is-invalid{
    border-color: $rojo !important;
}

/* SELECTS */
.css-bg1rzq-control, .css-1ajl57w-control{
    border-radius: 5px !important;
    border: 1px solid $gris_oscuro !important;
    font-size: 13px !important;
    height: 32px !important;
    min-height: unset !important;
    .css-151xaom-placeholder{
        font-size: 13px;
    }
}
.css-es53b3-multiValue {
    border-radius: 0.5rem !important;
}
.css-3pbdf8-control {
    border-radius: 5px !important;
    border: 1px solid $gris_oscuro !important;
    font-size: 13px !important;
}

.css-o5t5i1-control {
    border-radius: 5px !important;
    border-color: $naranja !important;
    box-shadow: 0 0 0 1px $naranja !important;
}

/*Icono de expandible*/
.css-16pqwjk-indicatorContainer {
    color: $gris_oscuro !important;
    padding: 0 8px 0 2px !important;
}
.css-1thkkgx-indicatorContainer {
    color: $gris_oscuro !important;
    padding: 0 8px 0 2px !important;
}
/*Icono separador*/
.css-bgvzuu-indicatorSeparator{
    background-color: white !important;
}
/*Selecto cuando esta activo*/
.css-1szy77t-control, .css-juf2xh-control{
    border-radius: 5px !important;
    font-size: 13px !important;
    height: 32px !important;
    min-height: unset !important;
    border-color: $naranja !important;
    box-shadow: 0 0 0 1px $naranja !important;
}

/*Selecto cuando esta desactivado*/
.css-14ojxsb-control{
    border-radius: 5px !important;
    border: 1px solid #ced4da !important;
    font-size: 13px !important;
    height: 32px !important;
    min-height: unset !important;
    background-color: #e9ecef !important;
}
/*El texto de adentro*/
.css-dvua67-singleValue{
    font-size: 13px !important;
    color: $gris_oscuro !important;
}
.css-ue83k8-singleValue{
    font-size: 13px !important;
    color: $gris_oscuro !important;
}
/*Items del select*/
.css-kj6f9i-menu{
    z-index: 8000 !important;
    font-size: 13px !important;
    color: $gris_oscuro !important;
    border: 1px $naranja solid !important;
}

/* Hover en el menu de seleccion */
.css-dpec0i-option {
    background-color: $gris_claro !important;
    border-radius: 4px !important;
    color: $gris_oscuro !important;
    cursor: default;
    display: block;
    font-size: inherit;
    padding: 8px 12px;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    box-sizing: border-box;
}

/* Item del menu */
.css-fk865s-option {
    background-color: transparent;
    color: inherit;
    cursor: default;
    display: block;
    font-size: inherit;
    padding: 8px 12px;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    box-sizing: border-box;
}

/* Item seleccionado del menu */
.css-xo7z33-option {
    background-color: $naranja !important;
    border-radius: 4px !important;
    color: hsl(0,0%,100%);
    cursor: default;
    display: block;
    font-size: inherit;
    padding: 8px 12px;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    box-sizing: border-box;
}

.invalid-force{
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: $rojo;
}


/* Checkbox and radio button */
.c-checkbox,
.c-radio {
  margin-right: 4px;
}
.c-checkbox *,
.c-radio * {
  cursor: pointer;
}
.c-checkbox input,
.c-radio input {
  opacity: 0;
  position: absolute;
  margin-left: 0 !important;
}
.c-checkbox span,
.c-radio span {
  position: relative;
  display: inline-block;
  vertical-align: sub;
  width: 18px;
  height: 18px;
  border: 1px solid $gris_oscuro;
  text-align: center;
}
.c-checkbox span:before,
.c-radio span:before {
  margin-left: 1px;
}

.c-radio:hover span {
    border: 2px solid $naranja;
}
.c-checkbox:hover span{
    border: 2px solid $naranja;
}
.form-inline .c-checkbox span, .form-inline
.c-radio span {
  margin-left: 0;
}
.c-checkbox.c-checkbox-rounded span, .c-checkbox.c-radio-rounded span,
.c-radio.c-checkbox-rounded span,
.c-radio.c-radio-rounded span {
  border-radius: 500px;
}

/* override for radio */
.c-radio span {
  border-radius: 500px;
}

/* the icon */
.c-checkbox span:before,
.c-radio span:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  text-align: center !important;
  font-size: 13px;
  line-height: 18px;
  vertical-align: middle;
}

/* Checked state */
.c-checkbox,
.c-radio {
  /* override for radio */
  /* Disable state */
  /* override for radio */
}
.c-checkbox input[type=checkbox]:checked + span:before,
.c-checkbox input[type=radio]:checked + span:before,
.c-radio input[type=checkbox]:checked + span:before,
.c-radio input[type=radio]:checked + span:before {
  color: white;
  opacity: 1;
  transition: color .3s ease-out;
}
.lectura input[type=checkbox]:checked + span:before,
.lectura input[type=radio]:checked + span:before,
.lectura input[type=checkbox]:checked + span:before,
.lectura input[type=radio]:checked + span:before {
  color: $naranja;
  opacity: 1;
  transition: color .3s ease-out;
  cursor: initial;
}
.c-checkbox input[type=checkbox]:checked + span,
.c-checkbox input[type=radio]:checked + span,
.c-radio input[type=checkbox]:checked + span,
.c-radio input[type=radio]:checked + span {
  border-color: $naranja;
  background-color: $naranja;
}
.lectura input[type=checkbox]:checked + span,
.lectura input[type=radio]:checked + span,
.lectura input[type=checkbox]:checked + span,
.lectura input[type=radio]:checked + span {
  border-color: #fff !important;
  background-color: #eff3f6 !important;
  cursor: initial;
}
.lectura{
  cursor: initial !important;
}
.lec{
    border: 0px solid #eff3f6 !important;
}
.c-checkbox input[type=radio]:checked + span,
.c-radio input[type=radio]:checked + span {
  background-color: #fff;
}
.c-checkbox input[type=radio]:checked + span:before,
.c-radio input[type=radio]:checked + span:before {
  color: $naranja;
}
.c-checkbox input[type=checkbox]:disabled + span,
.c-checkbox input[type=radio]:disabled + span,
.c-radio input[type=checkbox]:disabled + span,
.c-radio input[type=radio]:disabled + span {
  border-color: $gris_oscuro !important;
  background-color: $gris_letras !important;
}
.c-checkbox input[type=radio]:disabled + span,
.c-radio input[type=radio]:disabled + span {
  background-color: #fff !important;
}
.c-checkbox input[type=radio]:disabled + span:before,
.c-radio input[type=radio]:disabled + span:before {
  color: #ddd;
}

.c-radio.c-radio-nofont {
  /* override for radio */
  /* Disable state */
  /* override for radio */
}
.c-radio.c-radio-nofont span:before {
  content: "";
  width: 10px;
  height: 10px;
  top: 50%;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
  border-radius: 500px;
}
.c-radio.c-radio-nofont input[type=radio]:checked + span:before {
  color: #fff;
  opacity: 1;
  transition: color .3s ease-out;
}
.c-radio.c-radio-nofont input[type=radio]:checked + span {
  /* border-color: $naranja; */
  border: 2px solid $naranja;
  background-color: $naranja;
}
.c-radio.c-radio-nofont input[type=radio]:checked + span {
  background-color: #fff;
}
.c-radio.c-radio-nofont input[type=radio]:checked + span:before {
  background-color: $naranja;
}
.c-radio.c-radio-nofont input[type=radio]:disabled + span {
  border-color: $gris !important;
  background-color: $gris !important;
}
.c-radio.c-radio-nofont input[type=radio]:disabled + span {
  background-color: $gris_letras !important;
  border-color: $gris_oscuro !important;
}
.c-radio.c-radio-nofont input[type=radio]:disabled + span:before {
    background-color: $gris_oscuro;
}

/* Switch */
.react-switch-bg{
    border-radius: 6px !important;
    /* border: solid 1px $gris_oscuro !important; */
    background: $gris_oscuro;
    color: $gris_oscuro !important;
}

.react-switch-handle[aria-checked="false"]{
    height: 13px !important;
    width: 13px !important;
    top: 2px !important;
    box-shadow: none !important;
    background: $gris_oscuro !important;
    transform: translateX(3px) !important;
}

.react-switch-handle{
    height: 13px !important;
    width: 13px !important;
    top: 2px !important;
    box-shadow: none !important;
    background: white !important;
    transform: translateX(19px);
}

.day-container{
    padding-right: 0 !important;
    padding-left: 0 !important;

    .col-2 {
        justify-content: start !important;
    }
    @media (max-width: $sm) {
        padding-right: 0 !important;
        padding-left: 0 !important;

        .col-md-12{
            padding-right: 0 !important;
            padding-left: 0 !important;

            .col-10{
                padding-right: 0 !important;
                padding-left: 0 !important;
            }
            .col-2{
                padding-right: 0 !important;
                padding-left: 0 !important;
                justify-content: center !important;
            }
        }
    }
}

/* DATE PICKER */
.date-container {
    padding-right: 0 !important;

    .col-11{
        padding-right: 0 !important;
    }
    .col-1 {
        justify-content: start !important;
    }
    @media (max-width: $sm) {
        padding-left: 0 !important;

        .col-11{
            padding-left: 0 !important;
        }
        .col-1 {
            justify-content: center !important;
        }
    }
}
.day-picker-profesores{
    padding-left: 0 !important;
    padding-right: 0 !important;

    .col-md-12{
        padding-right: 0 !important;
    }

    @media (max-width: $sm) {
        .col-md-12{
            padding-left: 0 !important;
        }
    }
}

/* TIME PICKER */
.time-container {
    padding-right: 0 !important;

    .col-11{
        padding-right: 0 !important;
    }
    .col-1 {
        justify-content: start !important;
    }
    @media (max-width: $sm) {
        padding-left: 0 !important;

        .col-11{
            padding-left: 0 !important;
            .col-md-12{
                padding-right: 0 !important;
                padding-left: 0 !important;

                .time-picker-profesores{
                    padding-right: 0 !important;
                    padding-left: 0 !important;

                    .col-12 {
                        padding-right: 0 !important;
                        padding-left: 0 !important;
                    }
                }
            }
        }
        .col-1 {
            justify-content: center !important;
        }
    }
}
.time-picker-profesores {
    padding-right: 0 !important;
    @media (max-width: $sm) {
        padding-right: 0 !important;
        padding-left: 0 !important;

        .col-12{
            padding-right: 0 !important;
            padding-left: 0 !important;
        }
    }
}
.react-time-picker{
    width: 100% !important;
}
.react-time-picker__wrapper{
    height: 30px;
    width: 100% !important;
    color: $gris_oscuro !important;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid $gris_oscuro;
    border-radius: 5px;
}
.react-time-picker__inputGroup{
    justify-content: center;
    input {
        width: 30% !important;
        font-size: 15px;
        text-align: center;
        color: $gris_oscuro !important;
    }
    span {
        width: 30% !important;
        font-size: 15px;
        text-align: center;
    }
}
.react-time-picker__inputGroup__input {
    /* color: $gris-dark; */
}
.react-time-picker__inputGroup__leadingZero{
    display: none !important;
}
.react-time-picker__inputGroup__hour{
    min-width: 20px;
    justify-content: center;
    @media (max-width: $sm) {
        min-width: 25px;
    }
}
.react-time-picker__inputGroup__input:focus {
    /* border-color: $info !important; */
    outline: $naranja solid 1px !important;
    /* box-shadow: unset !important; */
}
.react-time-picker__inputGroup__amPm option:focus{
    outline: $naranja solid 1px !important;
    /* background-color: $secondary; */
}

.toolbar-search {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.icon-search {
    position: absolute !important;
    left: 4%;
    top: 3.5px !important;
}

.search-input {
    box-shadow: 0px 3px 6px #00000029 !important;
    background-size: 20px 18px;
    background-position: 98% center;
    background-repeat: no-repeat;
}

/* CSS del Slider */
.rc-slider-rail {
    position: absolute;
    width: 100%;
    background-color: $gris_claro !important;
    height: 4px;
    border-radius: 6px;
}

.rc-slider-track {
    position: absolute;
    left: 0;
    height: 4px;
    border-radius: 6px;
    background-color: $naranja !important;
}

.rc-slider-handle {
    position: absolute;
    width: 12px;
    height: 12px;
    cursor: pointer;
    cursor: -webkit-grab;
    margin-top: -4px;
    cursor: pointer;
    border-radius: 50%;
    border: none !important;
    background-color: $naranja !important;
    -ms-touch-action: pan-x;
    touch-action: pan-x;
}

.rc-slider-handle:active {
    border-color: $naranja !important;
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.rc-slider-handle:hover {
    border-color: $naranja !important;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: $naranja !important;
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
}

/* MONTH DATE PICKER ANTD */
.ant-picker:hover {
    border: 1px solid $gris_oscuro;
}

.month-picker {
    color: $gris_oscuro !important;
    background-color: #fff;
    /* background-clip: padding-box; */
    border: 1px solid $gris_oscuro;
    border-radius: 5px;
    box-shadow:  none !important;
    font-family: 'Raleway', sans-serif !important;
}

.ant-picker-month-panel{
    width: 250px;
}

.ant-picker-focused, .ant-picker-focused:hover {
    box-shadow:  none !important;
    border: 2px solid $naranja;
}

.ant-picker-panel-container {
    border-radius: 5px;
}

.ant-picker-cell-selected {
    .ant-picker-cell-inner {
        background-color: $naranja !important;
    }
}

.ant-picker-year-btn {
    font-weight: bold !important;
    color: $azul !important;
}

.ant-picker-input > input {
    text-align: center;
}

.ant-picker-content {
    height: 200px !important;
}

.ant-picker-dropdown {
    font-size: 13px;
    color: $gris_oscuro !important;
    font-family: 'Raleway', sans-serif !important;
    line-height: 1.5715;
}

.ant-picker-input > input {
    color: $gris_oscuro !important;
    font-size: 13px;
    font-family: 'Raleway', sans-serif !important;
}
