/* Colores base */
$primary: #FA7D09;
$cian: #366AED;

$naranja: #FA7D09;
$azul: #10426D;
$rojo: #D92027;
$gris_oscuro: #323232;
$gris: #555555;
$gris_claro: #d7d7d7;
$gris_letras: #888888;
$verde: #7EA04D;
$gris-muy-claro: #F1F1F1;

/* Clases de colores para texto */
.blanco {
    color: white !important;
}

.naranja {
    color: $naranja;
}

.azul {
    color: $azul;
}

.rojo {
    color: $rojo;
}

.gris-oscuro {
    color: $gris_oscuro;
}

.gris {
    color: $gris;
}

.gris-claro {
    color: $gris_claro;
}

.gris-letras {
    color: $gris_letras;
}

.verde {
    color: $verde;
}

.gris-muy-claro {
    color: $gris-muy-claro;
}

/* Fondos de los colores */
.bg-naranja {
    background-color: $naranja;
}

.bg-azul {
    background-color: $azul;
}

.bg-rojo {
    background-color: $rojo;
}

.bg-gris-oscuro {
    background-color: $gris_oscuro;
}

.bg-gris {
    background-color: $gris;
}

.bg-gris-claro {
    background-color: $gris_claro !important;
}

.bg-gris-letras {
    background-color: $gris_letras;
}

.bg-verde {
    background-color: $verde;
}

.bg-gris-muy-claro {
    background-color: $gris-muy-claro;
}
