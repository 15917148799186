@import '../configs/color.css';
@import '../configs/breakpoints.css';

.text-lg-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
        @media (max-width: calc($lg - 1px)) {
            white-space: normal;
        }
}

.action_img {
    height: 22px;
    cursor: pointer;
    margin-bottom: 5px;
    margin-top: 5px;
}

/* Componente de Paginacion de ANTD */
.ant-pagination.mini .ant-pagination-item {
    min-width: unset !important;
    height: 33px !important;
    width: 30px !important;
    margin: 0 !important;
    line-height: 30px !important;
    font-family: 'Raleway', sans-serif !important;
    font-size: 14px !important;
}

.ant-pagination-item-active {
    background-color: $azul;
    border-color: $azul;
    color: white;
}

/* .ant-pagination-item {
    background-color: white;
    border-radius: 0 !important;
    color: $gris_oscuro;
} */

.ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next {
    min-width: unset !important;
    height: 33px !important;
    width: 31.14px !important;
    margin: 0;
    line-height: 10px !important;
    background-color: white;
}

.ant-pagination.mini .ant-pagination-prev {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.ant-pagination.mini .ant-pagination-next {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
}

.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
    color: inherit !important;
    border-color: transparent !important;
}

.ant-pagination-disabled,
.ant-pagination-disabled:hover,
.ant-pagination-disabled:focus {
    color: rgba(0, 0, 0, 0.25) !important;
    cursor: not-allowed;
    border-color: transparent !important;
}

.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
    border-color: $azul !important;
}

.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
    background: white !important;
    border-color: white !important;
    color: $gris_oscuro !important;
    border-radius: 0 !important;
}

/* Hacer oferta */
.img-oferta {
    border-radius: 50%;
    border: 1px solid $naranja;
    width: 35px;
    height: 35px;
    padding: 2px;
}

/*hacer las estrellas de calificacion mas grandes*/
.estrellaGrande span {
    font-size: 25px;
}

@media only screen and (max-width: 500px) {
    .estrellaGrande span {
        font-size: 20px;
    }
}

.ant-rate-star-zero span {
    color: #d7d7d7 !important;
}


/* IMAGE VIEWVER */
.ant-modal-mask,
.ant-image-preview-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 107000 !important;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    filter: alpha(opacity=50);
}

.ant-modal-wrap,
.ant-image-preview-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    outline: 0;
    z-index: 107000 !important;
    -webkit-overflow-scrolling: touch;
}

.ant-image-preview-operations {
    color: rgba(255, 255, 255, 0.85) !important;
    background: rgba(0, 0, 0, 0.7) !important;
}

.image-puntero {
    cursor: pointer;
}

/* CSS FROM CHAT */
.chat {
    min-height: 60vh;
    overflow-y: auto;
    max-height: 60vh;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.input-chat {
    border-radius: 22px !important;
    padding: 1rem;
    font-size: 13px;
}

.btn-chat {
    border: 1px solid #1664A0;
    color: white;
    box-shadow: none;
    background-color: #1664A0;
    border-radius: 50% !important;
    width: 37px;
    height: 37px;
    padding: 3px;
    cursor: pointer;
}

/* CSS FROM COMENTARIOS */
.comentarios {
    overflow-y: auto;
    max-height: 40vh;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

/* Bordes de cada publicidad */
.border-publicidad {
    border: 1px solid #707070;
    border-radius: 10px;
}

/* Componente imagen  */
.componente-imagen>img {
    width: auto;
}

.cursor-desactivado {
    cursor: not-allowed !important;
}

.tamano-modal {
    max-width: 550px !important;
}

/* Banner de publicidad */
.ant-collapse-content>.ant-collapse-content-box {
    padding: 0;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    position: relative;
    padding: 5px 4px;
    padding-left: 40px;
    font-size: 12px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
    padding-bottom: 0;
}

.carousel .slider-wrapper {
    border-radius: 10px;
}

/* Collapse para la contrasena */
.collapse-contrasena>.ant-collapse>.ant-collapse-item>.ant-collapse-header {
    position: relative;
    padding: 7px 16px;
    padding-left: 40px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 1.5715;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.collapse-contrasena>.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    top: 11px;
}
/* boton contraseña(svg ojo) para poder ver la contraseña o ocultarlo */
.btn-password {
    background-color: transparent !important;
    border: none !important;
    position: absolute;
    /* left: 90%; */
    right: 5%;
    bottom: 0%;
}

.btn-password:focus {
    outline: none !important;
}

.imagenes-viaje,
.imagenes-viaje>img {
    max-height: 70vh;
    width: 100%;
    overflow: hidden;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 750px) and (orientation: landscape) {

    .imagenes-viaje,
    .imagenes-viaje>img {
        width: auto;
    }
}

.tamano-carousel ul {
    height: 0;
    padding-bottom: 10.23% !important;
}

/* - - - hover para poder abrir el modal de ver perfil transportista */
.ver-perfil {
    position:absolute;
    height: 100%;
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    font-size: 15px;
    cursor: pointer;
}

.ver-perfil > span{
    display: none;
}

.ver-perfil:hover{
    display: flex;
    background-color: rgba(0, 0, 0, 0.288);
}

.ver-perfil:hover > span{
    display: block;
}

.blink-danger {
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}
/* validacion de datos en un Swal */
.my-validation-message::before {
    display: none;
  }

  .my-validation-message i {
    margin: 0 .4em;
    color: #f27474;
    font-size: 1.4em;
  }

/* z-INDEX swal2 */
.swal2-container.swal2-shown {
    z-index: 1000000;
}
